import React from "react"
import Layout from "../components/Layout"

export default () => (
  <Layout>
    <div>
      <p><h1>Turskaa tuli! Valitettavasti etsimääsi sivua ei löydy! <a href="https://vedonlyonti.app">Siirry etusivulle...</a></h1></p>
    </div>
  </Layout>
)
